<script setup>
import { useRoute, useRouter } from 'vue-router'
import { getWebsites } from '../api'
import { ref } from 'vue'
import { removeNullValuesFromObj } from '../helpers'
import WebsiteTable from '../components/WebsiteTable.vue'
import WebsiteSearchBar from '../components/WebsiteSearchBar.vue'
import BaseSpacer from '../components/BaseSpacer.vue'

const router = useRouter()
const route = useRoute()
const websites = ref([])
const searchParams = ref(route.query || {})
websites.value = await getWebsites(searchParams.value)

const handleSearch = async (params) => {
  searchParams.value = { ...searchParams.value, ...params }
  searchParams.value = removeNullValuesFromObj(searchParams.value)
  const result = await getWebsites(searchParams.value)
  websites.value = result
  router.push({ name: 'websiteIndex', query: searchParams.value })
}
</script>

<template>
  <main>
    <header class="header">
      <h4>サーバー監視一覧</h4>
      <BaseSpacer />
      <BaseButton @click="router.push('/websites/create')">
        新規登録
      </BaseButton>
    </header>
    <section
      class="mx-auto"
      style="max-width: 1260px;">
      <BaseSpacer />
      <section
        class="mx-auto"
        style="max-width: 720px;">
        <WebsiteSearchBar
          :search-params="searchParams"
          @search="handleSearch" />
      </section>
      <BaseSpacer />
      <WebsiteTable
        :websites="websites"
        :search-params="searchParams"
        @search="handleSearch" />
    </section>
  </main>
</template>
