<script setup>
import { useAttrs } from 'vue'

defineProps({
  color: {
    type: String,
    default: 'primary',
  },
  size: {
    type: String,
    default: 'md',
  },
  isSubmitting: {
    type: Boolean,
    default: false,
  },
})
const attrs = useAttrs()
</script>

<template>
  <BaseButton
    type="submit"
    :class="[
      { disabled: isSubmitting },
      `btn btn-outline-${color} btn-${size}`,
    ]"
    v-bind="attrs">
    <slot />
    <BaseSpinner v-if="isSubmitting" />
  </BaseButton>
</template>
