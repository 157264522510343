<script setup>
import { useAttrs } from 'vue'
import useModelWrapper from '@/composables/useModelWrapper'
const props = defineProps({
  label: {
    type: String,
    default: '',
  },
  errors: {
    type: Array,
    default: () => []
  },
  description: {
    type: String,
    default: '',
  },
  modelValue: {
    type: [String, Number, Boolean, null],
    default: null,
  },
  maxSize: {
    type: Number,
    default: 12,
    validator(value) {
      return [...Array(12)].map((_, i) => i + 1).includes(value)
    }
  }
})
const attrs = useAttrs()
const emit = defineEmits(['update:modelValue'])
const value = useModelWrapper(props, emit)
</script>

<template>
  <div class="row">
    <label :class="`d-block text-start col-md-${maxSize}`">
      {{ label }}
      <BaseSpacer size="sm" />
      <BaseValidationErrors :errors="errors" />
      <div class="row gx-0">
        <BaseRadio
          v-model="value"
          v-bind="attrs" />
      </div>
    </label>
    <p
      v-if="description"
      class="col-md-12 text-start mb-0">
      <small class="fw-light">{{ description }}</small>
    </p>
  </div>
</template>
