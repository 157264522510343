<script setup>
import { getMonitor, saveMonitors } from '../api'
import { inject } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useSubmit } from '../composables/useSubmit'
import MonitorForm from '../components/MonitorForm.vue'

const appAlert = inject('appAlert')
const route = useRoute()
const router = useRouter()
const id = route.params.id

const monitor = await getMonitor(id)

const { submit, isSubmitting } = useSubmit(async (form) => {
  const result = await saveMonitors(form)
  appAlert(`正常に更新されました （ID: ${result.id}, 名前: ${result.name}）`)

  router.push({ name: 'monitorIndex' })
})
</script>

<template>
  <div>
    <h2 class="fs-4">
      通知先更新
    </h2>
    <MonitorForm
      :is-submitting="isSubmitting"
      :initial-value="monitor"
      @submit="submit">
      <template #submitText>
        更新する
      </template>
    </MonitorForm>
  </div>
</template>
