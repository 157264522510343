<script setup>
defineProps({
  websites: {
    type: Array,
    default: () => []
  },
  searchParams: {
    type: Object,
    required: true,
  }
})
const emit = defineEmits(['search'])
</script>

<template>
  <BaseTableScrollable>
    <template #thead>
      <tr>
        <th>
          ID
          <BaseOrderIcon
            :initial-order="searchParams.orderId"
            @change="(order) => emit('search', { orderId: order })" />
        </th>
        <th>
          名前
          <BaseOrderIcon
            :initial-order="searchParams.orderName"
            @change="(order) => emit('search', { orderName: order })" />
        </th>
        <th>
          URL
          <BaseOrderIcon
            :initial-order="searchParams.orderUrl"
            @change="(order) => emit('search', { orderUrl: order })" />
        </th>
        <th>
          監視状態
          <BaseOrderIcon
            :initial-order="searchParams.orderStatus"
            @change="(order) => emit('search', { orderStatus: order })" />
        </th>
        <th>
          最終確認時刻
        </th>
        <th>
          ダウン時刻
        </th>
        <th>
          稼働状態
          <BaseOrderIcon
            :initial-order="searchParams.orderIsActive"
            @change="(order) => emit('search', { orderStatus: order })" />
        </th>
      </tr>
    </template>
    <template #tbody>
      <tr
        v-for="website in websites"
        :key="website">
        <td>
          <template v-if="website.deleting">
            <BaseSpinner />削除中
            {{ website.id }}
          </template>
          <template v-else>
            <router-link
              :to="`/websites/${website.id}/edit`"
              class="edit-link">
              <i class="bi bi-pencil-square" />
              {{ website.id }}
            </router-link>
          </template>
        </td>
        <td>
          <p class="wrap-words">
            {{ website.name }}
          </p>
        </td>
        <td>
          <a
            :href="website.url"
            target="_blank">
            <p class="wrap-words">
              {{ website.url }}
            </p>
          </a>
        </td>
        <td>
          <span v-if="website.status === 'Healthy'">
            <i class="bi bi-check-square text-success" />
            正常
          </span>
          <span v-else>
            <i class="bi bi-exclamation-square text-danger" />
            異常
          </span>
        </td>
        <td>
          <span>{{ website.latestHealthCheckExecutedAt ? new Date(website.latestHealthCheckExecutedAt).toLocaleString() : '' }}</span>
        </td>
        <td>
          <span>{{ website.status !== 'Healthy' ? new Date(website.lastDownedAt).toLocaleString() : '' }}</span>
        </td>
        <td>
          <span
            v-if="website.isActive"
            class="text-dark">
            <i class="bi bi-check-circle" />
            稼働中
          </span>
          <span
            v-else
            class="text-warning">
            <i class="bi bi-stop-circle" /> 
            停止中
          </span>
        </td>
      </tr>
    </template>
  </BaseTableScrollable>
</template>

<style lang="scss" scoped>
.wrap-words {
  width: 300px;
  white-space: normal;
  word-break: break-all;
  margin: 0;
}
</style>