<script setup>
import { computed } from 'vue'

const props = defineProps({
  size: {
    type: String,
    validator(value) {
      return ['sm', 'md', 'lg'].includes(value)
    },
    default: 'md',
  },
})
const sm = computed(() => props.size == 'sm')
const md = computed(() => props.size == 'md')
const lg = computed(() => props.size == 'lg')
</script>

<template>
  <div
    v-if="sm"
    class="m-2" />
  <div
    v-if="md"
    class="m-4" />
  <div
    v-if="lg"
    class="m-5" />
</template>
