<template>
  <button
    type="button"
    class="btn"
    :class="`btn-outline-${color} btn-${size}`">
    <slot />
  </button>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'primary',
    },
    size: {
      type: String,
      default: 'md',
    },
  },
}
</script>
