<script setup>
import { getMonitors } from '../api'

const monitors = await getMonitors()
</script>

<template>
  <BaseTableScrollable>
    <template #thead>
      <tr>
        <th>ID</th>
        <th>名前</th>
      </tr>
    </template>
    <template #tbody>
      <tr
        v-for="(monitor, i) in monitors"
        :key="i">
        <td>
          <router-link
            :to="{
              name: 'monitorEdit',
              params: { id: monitor.id },
            }">
            <i class="bi bi-pencil-square" />
            {{ monitor.id }}
          </router-link>
        </td>
        <td>
          {{ monitor.name }}
        </td>
      </tr>
    </template>
  </BaseTableScrollable>
</template>
