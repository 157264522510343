<script setup>
import { ref } from 'vue'
import { getTags, getMonitorOptions } from '../api'
const props = defineProps({
  initialValue: {
    type: Object,
    default: () => ({
      id: null,
      url: '',
      name: '',
      interval: 5,
      coolTime: 2,
      responseTimeout: 10,
      description: '',
      tags: [],
      monitorIds: [],
      isActive: true
    }),
  },
  isSubmitting: {
    type: Boolean,
    default: false,
  },
  isDeleting: {
    type: Boolean,
    default: false,
  }
})
const tagOptions = getTags
const isActiveOptions = [
  {
    value: true,
    label: '監視を稼働'
  },
  {
    value: false,
    label: '監視を停止'
  }
]
const monitorOptions = getMonitorOptions
const emit = defineEmits(['submit', 'delete'])
const form = ref(JSON.parse(JSON.stringify(props.initialValue)))
const handleSubmit = () => emit('submit', form.value)
const handleDelete = () => emit('delete', form.value.id)
</script>

<template>
  <BaseContainerForm>
    <form
      class="row gy-4"
      @submit.prevent="handleSubmit">
      <BaseFieldset>
        <template #legend>
          <small>監視設定</small>
        </template>
        <BaseInputGroup
          v-model="form.name"
          :max-size="8"
          label="名前"
          type="text"
          required />
        <BaseSpacer />
        <BaseInputGroup
          v-model="form.url"
          :max-size="8"
          label="URL"
          type="url"
          required />
        <BaseSpacer />
        <BaseInputGroup
          v-model="form.interval"
          :max-size="4"
          label="ヘルスチェック間隔（分）"
          type="number"
          min="1"
          max="60" 
          required />
        <BaseSpacer />
        <BaseInputGroup
          v-model="form.coolTime"
          :max-size="4"
          label="再通知間隔（時間）"
          description="一度目のダウン通知後、サイトが復旧しない場合、上記時間後に再度通知を行います。"
          type="number"
          min="1"
          max="60" 
          required />
        <BaseSpacer />
        <BaseInputGroup
          v-model="form.responseTimeout"
          :max-size="4"
          label="レスポンスタイムアウト（秒）"
          description="リクエストの完了を待機する時間を設定します。上記時間内にレスポンスがない場合、異常と判断します。"
          type="number"
          min="1"
          max="60" 
          required />
        <BaseSpacer />
        <BaseRadioGroup
          v-model="form.isActive"
          name="isActive" 
          :max-size="4"
          label="稼働状態"
          description="監視を一時停止したい場合等は、「監視を停止」を選択すると、ヘルスチェックを実行しません。"
          :options="isActiveOptions" 
          required />
      </BaseFieldSet>
      <BaseFieldset>
        <template #legend>
          <small>通知先の設定</small>
        </template>

        <BaseMultiSelectTag
          v-model="form.monitorIds"
          label="通知先"
          :options="monitorOptions"
          :required="true" />
      </BaseFieldset>
      <BaseFieldset>
        <template #legend>
          <small>説明の設定<small>（任意）</small></small>
        </template>
        <BaseTextAreaGroup
          v-model="form.description"
          :max-size="12"
          rows="3"
          label="説明" />
        <BaseSpacer />
        <BaseMultiSelectTag
          v-model="form.tags"
          label="タグ"
          description="未使用のタグは、翌日0時に自動的に削除されます。"
          :create-tag="true"
          :options="tagOptions" />
      </BaseFieldset>
      <hr>
      <fieldset>
        <BaseSubmitButton :is-submitting="isSubmitting">
          <slot name="submitText">
            保存
          </slot>
        </BaseSubmitButton>
      </fieldset>
    </form>
    <fieldset v-if="form.id">
      <hr>
      <form @submit.prevent="handleDelete">
        <BaseSubmitButton
          :is-submitting="isDeleting"
          color="danger">
          削除する
        </BaseSubmitButton>
      </form>
    </fieldset>

    <BaseSpacer size="sm" />

    <p class="text-end">
      <router-link to="/">
        一覧ページへ戻る <i class="bi bi-arrow-90deg-right" />
      </router-link>
    </p>
  </BaseContainerForm>
</template>
