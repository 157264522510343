import { ref } from 'vue'

export function useSubmit(callback) {
  const isSubmitting = ref(false)
  const submit = async (form) => {
    isSubmitting.value = true
    try {
      await callback(form)
    } catch (error) {
      isSubmitting.value = false
      throw error 
    } 
  }

  return {
    isSubmitting,
    submit,
  }
}