import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'

// css frameworks
import 'bootstrap/dist/css/bootstrap.min.css'

// icons
import 'bootstrap-icons/font/bootstrap-icons.css'

const app = createApp(App)

// deepCopy
window.deepCopy = (obj) => JSON.parse(JSON.stringify(obj))

// Baseコンポーネントのグローバル登録
// reference: https://v3.vuejs.org/cookbook/automatic-global-registration-of-base-components.html

const requireComponent = require.context(
  // コンポーネントフォルダの相対パス
  './components',
  // サブフォルダ内を探すかどうか
  false,
  // ベースコンポーネントのファイル名とマッチさせるための正規表現
  /Base[A-Z]\w+\.(vue|js)$/
)

requireComponent.keys().forEach((fileName) => {
  // コンポーネント設定の取得
  const componentConfig = requireComponent(fileName)

  // コンポーネントのパスカルケースでの名前を取得
  const componentName = upperFirst(
    camelCase(
      // フォルダの深さに関わらずファイル名を取得
      fileName
        .split('/')
        .pop()
        .replace(/\.\w+$/, '')
    )
  )

  app.component(
    componentName,
    // `.default` にあるコンポーネントのオプションを探す。
    // コンポーネントが `export default` でエクスポートされていれば存在して、
    // そうでなければモジュールのルートのフォールバックする。
    componentConfig.default || componentConfig
  )
})

app.use(router).mount('#app')
