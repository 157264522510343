// オブジェクトから、値がnullのプロパティを消去
// ex. { a: null, b: 'hoge' } => { b: 'hoge' }
export function removeNullValuesFromObj(object) {
  const copy = JSON.parse(JSON.stringify(object))
  for (const [key, value] of Object.entries(copy)) {
    if (value === null) {
      delete copy[key]
    }
  }
  return copy
}