<script setup>
defineProps({
  kind: {
    type: String,
    validator(value) {
      return ['success', 'warning', 'danger'].includes(value)
    },
    default: 'success',
  },
  isClosable: {
    type: Boolean,
    default: false
  }
})
const emit = defineEmits(['close'])
const handleClose = () => emit('close')
</script>

<template>
  <div
    :class="`alert alert-${kind} alert-dismissible`"
    role="alert">
    <i
      v-if="kind === 'success'"
      class="bi bi-check-circle icon-space" />
    <i
      v-else-if="kind === 'danger'"
      class="bi bi-x-circle icon-space" />
    <slot />
    <button
      v-if="isClosable"
      type="button"
      class="btn-close"
      aria-label="Close"
      @click.prevent="handleClose" />
  </div>
</template>

<style scoped>
.icon-space::after {
  content: " ";
}
</style>
