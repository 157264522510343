<script setup>
import { ref } from 'vue'
import BaseSubmitButton from './BaseSubmitButton.vue'

const props = defineProps({
  searchParams: {
    type: Object,
    required: true,
  }
})

const emit = defineEmits(['search'])
const keyword = ref(props.searchParams.keyword || '')
const handleSearch = () => emit('search', { keyword: keyword.value })
</script>

<template>
  <form
    class="d-flex"
    @submit.prevent="handleSearch">
    <BaseInput
      v-model="keyword"
      type="search"
      placeholder="フリーワード検索（名前,URL,タグ名など）"
      aria-label="Search" />
    <BaseSubmitButton
      style="width: 4rem;">
      検索
    </BaseSubmitButton>
  </form>
</template>
