<template>
  <div class="w-100">
    <small />
  </div>
</template>

<script>
export default {
  name: 'AppFooter',
}
</script>
