<script setup>
import { deleteWebsite, getWebsiteForm, saveWebsite } from '../api'
import { inject } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useSubmit } from '../composables/useSubmit'
import WebsiteForm from '@/components/WebsiteForm.vue'

const appAlert = inject('appAlert')
const router = useRouter()
const route = useRoute()
const id = route.params.id

const website = await getWebsiteForm(id)

const { submit, isSubmitting } = useSubmit(async (form) => {
  const result = await saveWebsite(form)
  appAlert(`正常に更新されました （ID: ${result.id}, 名前: ${result.name}）`)

  router.push({ name: 'websiteIndex' })
})
const { submit: handleDelete, isSubmitting: isDeleting } = useSubmit(async (id) => {
  if (!confirm('本当に削除しますか?')) {
    return
  }

  const result = await deleteWebsite(id)
  appAlert(`正常に削除されました （ID: ${result.id}, 名前: ${result.name}）`)

  router.push({ name: 'websiteIndex' })
})
</script>

<template>
  <div>
    <h2 class="fs-4">
      サーバー監視更新
    </h2>
    <WebsiteForm
      :is-submitting="isSubmitting"
      :is-deleting="isDeleting"
      :initial-value="website"
      @submit="submit"
      @delete="handleDelete">
      <template #submitText>
        更新する
      </template>
    </WebsiteForm>
  </div>
</template>
