import { ref, watch } from 'vue'
import { useRoute } from 'vue-router'

export function useAlert() {
  const msg = ref(null)
  const isShowAlert = ref(false)
  const isClosable = ref(false)
  const alertKind = ref('success')

  const appAlert = (newMsg, kind = 'success') => {
    // 初期化
    isShowAlert.value = false
    isClosable.value = false

    msg.value = newMsg
    alertKind.value = kind
    isShowAlert.value = true

    // 表示後、1秒経過後に閉じられる（1秒は必ず表示する）
    setTimeout(() => {
      isClosable.value = true
    }, 1000)
  }

  const handleCloseAlert = () => {
    if (isShowAlert.value && isClosable.value) {
      isShowAlert.value = false
      isClosable.value = false
    }
  }

  // パス遷移持
  const route = useRoute()
  watch(() => route.path, () => {
    handleCloseAlert()
  })

  return {
    msg,
    isShowAlert,
    alertKind,
    appAlert,
    handleCloseAlert
  }
}