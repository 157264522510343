<script setup>
import useModelWrapper from '@/composables/useModelWrapper'
import { useAttrs } from 'vue'
const props = defineProps({
  name: {
    type: String,
    required: true
  },
  modelValue: {
    type: [String, Number, Boolean, null],
    default: null,
  },
  /**
   * [{
   *   value: 'hoge',
   *   label: 'HOGE',
   * }, ...]
   */
  options: {
    type: Array,
    default: () => []
  }
})
const attrs = useAttrs()
const emit = defineEmits(['update:modelValue'])
const value = useModelWrapper(props, emit)
</script>

<template>
  <div
    v-for="option in options"
    :key="option"
    class="form-check">
    <input
      :id="`radio-${option.label}-${option.value}`"
      v-model="value"
      :name="name"
      :required="attrs.required"
      :value="option.value"
      class="form-check-input"
      type="radio">
    <label
      class="form-check-label"
      :for="`radio-${option.label}-${option.value}`">
      {{ option.label }}
    </label>
  </div>
</template>