<script setup>
import { inject } from 'vue'
import { useRouter } from 'vue-router'
import { useSubmit } from '../composables/useSubmit'
import { deleteMonitor } from '../api'

const props = defineProps({
  id: {
    type: Number,
    required: true
  }
})

const appAlert = inject('appAlert')
const router = useRouter()

const { submit, isSubmitting } = useSubmit(async () => {
  if (confirm('本当に削除しますか?')) {
    const result = await deleteMonitor(props.id)
    appAlert(`正常に削除されました （ID: ${result.id}, 名前: ${result.name}）`)

    router.push({ name: 'monitorIndex' })
  }
})
</script>

<template>
  <form @submit.prevent="submit">
    <BaseSubmitButton
      :is-submitting="isSubmitting"
      color="danger">
      削除する
    </BaseSubmitButton>
  </form>
</template>