<script setup>
import { saveMonitors } from '../api'
import { inject } from 'vue'
import { useRouter } from 'vue-router'
import { useSubmit } from '../composables/useSubmit'
import MonitorForm from '../components/MonitorForm.vue'

const appAlert = inject('appAlert')
const router = useRouter()

const { submit, isSubmitting } = useSubmit(async (form) => {
  const result = await saveMonitors(form)
  appAlert(`正常に新規登録されました （ID: ${result.id}, 名前: ${result.name}）`)

  router.push({ name: 'monitorIndex' })
})
</script>

<template>
  <div>
    <h2 class="fs-4">
      通知先作成
    </h2>
    <MonitorForm
      :is-submitting="isSubmitting"
      @submit="submit" />
  </div>
</template>
