<script setup>
import useModelWrapper from '@/composables/useModelWrapper'
const props = defineProps({
  modelValue: {
    type: [String, Number, null],
    default: null,
  },
})
const emit = defineEmits(['update:modelValue'])
const value = useModelWrapper(props, emit)
</script>

<template>
  <textarea
    v-model="value"
    class="form-control" />
</template>
