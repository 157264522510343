import axiosBase from 'axios'

export const axios = axiosBase.create({
  baseURL: process.env.VUE_APP_API_HOST,
})

export function getWebsites(searchParams) {
  return axios.get('/websites', { params: searchParams }).then((res) => res.data.data)
}

export function getWebsite(id) {
  return axios.get(`/websites/${id}`).then((res) => res.data.data)
}

export function getWebsiteForm(id) {
  return getWebsite(id).then(data => ({
    ...data,
    monitorIds: data.monitors.map(monitor => monitor.id)
  }))
}

export function saveWebsite(newValue) {
  if (newValue.id) {
    return axios.put(`/websites/${newValue.id}`, newValue).then(res => res.data.data)
  } else {
    return axios.post('/websites', newValue).then(res => res.data.data)
  }
}

export function deleteWebsite(id) {
  return axios.delete(`/websites/${id}`).then(res => res.data.data)
}

export function getMonitors() {
  return axios.get('/monitors').then((res) => res.data.data)
}

export function getMonitor(id) {
  return axios.get(`/monitors/${id}`).then((res) => res.data.data)
}

export function saveMonitors(newValue) {
  if (newValue.id) {
    return axios.put(`/monitors/${newValue.id}`, newValue).then(res => res.data.data)
  } else {
    delete newValue.id
    return axios.post('/monitors', newValue).then(res => res.data.data)
  }
}

export function deleteMonitor(id) {
  return axios.delete(`/monitors/${id}`).then((res) => res.data.data)
}

export function getMonitorOptions() {
  return getMonitors().then(data => data.map(value => ({
    value: value.id,
    label: value.name
  })))
}

export function getTags() {
  return axios.get('/tags').then((res) => res.data.data)
}