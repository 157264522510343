<script setup>
import { ref } from 'vue'

const props = defineProps({
  initialOrder: {
    type: String,
    default: null,
    validator(value) {
      return ['asc', 'desc'].includes(value) || value === null
    }
  }
})
const order = ref(props.initialOrder || null)
const emit = defineEmits(['change'])
const handleClickAsc = () => {
  order.value = 'desc'
  emit('change', order.value)
}
const handleClickDesc = () => {
  order.value = null
  emit('change', order.value)
}
const handleClickNormal = () => {
  order.value = 'asc'
  emit('change', order.value)
}
</script>
<template>
  <i
    v-if="order === 'asc'"
    class="bi bi-arrow-down-circle-fill"
    @click="handleClickAsc" />
  <i 
    v-else-if="order === 'desc'"
    class="bi bi-arrow-up-circle-fill"
    @click="handleClickDesc" />
  <i
    v-else
    class="bi bi-arrow-down-circle"
    @click="handleClickNormal" />
</template>