// @ts-nocheck
import { createRouter, createWebHistory } from 'vue-router'
import WebsiteIndex from './views/WebsiteIndex'
import WebsiteCreate from './views/WebsiteCreate'
import WebsiteEdit from './views/WebsiteEdit'
import MonitorIndex from './views/MonitorIndex'
import MonitorCreate from './views/MonitorCreate'
import MonitorEdit from './views/MonitorEdit'
import NotFound from './views/NotFound'

const routes = [
  { 
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound
  },
  {
    path: '/',
    component: WebsiteIndex,
  },
  {
    path: '/websites',
    name: 'websiteIndex',
    component: WebsiteIndex,
  },
  {
    path: '/websites/create',
    name: 'websiteCreate',
    component: WebsiteCreate,
  },
  {
    path: '/websites/:id/edit',
    name: 'websiteEdit',
    component: WebsiteEdit,
  },
  {
    path: '/monitors',
    name: 'monitorIndex',
    component: MonitorIndex,
  },
  {
    path: '/monitors/create',
    name: 'monitorCreate',
    component: MonitorCreate,
  },
  {
    path: '/monitors/:id/edit',
    name: 'monitorEdit',
    component: MonitorEdit,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router
