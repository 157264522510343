<script setup>
import useModelWrapper from '@/composables/useModelWrapper'
import { useAttrs } from 'vue'
const props = defineProps({
  label: {
    type: String,
    default: ''
  },
  modelValue: {
    type: Array,
    required: true
  },
  newValue: {
    type: [Object, String, Number],
    required: true
  },
  required: {
    type: Boolean,
    default: false
  }
})
const attrs = useAttrs()
const emit = defineEmits(['update:modelValue'])
const wrapped = useModelWrapper(props, emit)
const add = () => wrapped.value.push(deepCopy(props.newValue))
const remove = i => wrapped.value.splice(i, 1)
if (wrapped.value.length === 0) {
  add()
}
</script>

<template>
  <div class="row gy-4 w-100">
    <div
      v-for="(element, i) in wrapped"
      :key="i">
      <div class="row gx-0">
        <div class="col-md-10">
          <BaseInput
            v-model="wrapped[i]"
            v-bind="attrs"
            :required="required && i === 0" />
        </div>
        <BaseButton
          v-if="i > 0"
          class="col-md-2"
          @click.prevent="() => remove(i)">
          <slot name="removeBtnText">
            Remove
          </slot>
        </BaseButton>
      </div>
    </div>
    <div>
      <BaseButton
        class="w-100"
        @click.prevent="add">
        <slot name="addBtnText">
          Add
        </slot>
      </BaseButton>
    </div>
  </div>
</template>
