<template>
  <p
    v-if="errors.length > 0"
    class="validation-errors">
    <small
      v-for="error in errors"
      :key="error">
      {{ error }}
    </small>
  </p>
</template>

<script>
export default {
  props: {
    errors: {
      type: Array,
      default() {
        return []
      },
    },
  },
}
</script>
<style lang="scss" scoped>
@use "@/assets/scss/_colors" as colors;

.validation-errors {
  margin: 0;
  font-size: 0.7em;
  color: colors.$danger;
}
</style>
