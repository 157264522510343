<script setup>
import AppFooter from '@/components/AppFooter.vue'
import { onErrorCaptured, provide } from 'vue'
import { useQueryProvider } from 'vue-query'
import { useAlert } from '@/composables/useAlert'
import AppAlert from './components/AppAlert.vue'

// アラート
const {
  msg,
  isShowAlert,
  alertKind,
  appAlert,
  handleCloseAlert
} = useAlert()

provide('appAlert', appAlert)

onErrorCaptured((e) => {
  appAlert(e, 'danger')
})

useQueryProvider()
</script>

<template>
  <div class="app-wrapper">
    <AppAlert
      :is-show="isShowAlert"
      :kind="alertKind"
      @close="handleCloseAlert">
      {{ msg }}
    </AppAlert>

    <header class="app-nav">
      <div>
        <router-link to="/">
          サーバー監視一覧
        </router-link> |
        <router-link to="/monitors">
          通知先一覧
        </router-link>
      </div>
    </header>

    <article class="app-main">
      <RouterView
        v-slot="{ Component }">
        <template v-if="Component">
          <Suspense>
            <div>
              <component :is="Component" />
            </div>
            <template #fallback>
              Loading...
            </template>
          </Suspense>
        </template>
      </RouterView>
    </article>

    <footer class="app-footer">
      <AppFooter />
    </footer>
  </div>
</template>

<style lang="scss">
@use "./assets/scss/app.scss";

.app-wrapper {
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto auto 1fr auto;
  grid-template-columns: minmax(0, 1fr);
}

.app-nav {
  padding: 30px;

  a {
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.app-main {
  padding: 30px;
}
</style>
