<script>
import useModelWrapper from '@/composables/useModelWrapper'
export default {
  props: {
    modelValue: {
      type: String,
      required: true,
    },
  },
  emit: ['update:modelValue'],
  setup(props, { emit }) {
    return {
      value: useModelWrapper(props, emit),
    }
  },
}
</script>

<template>
  <select
    v-model="value"
    class="form-select">
    <slot name="options" />
  </select>
</template>
