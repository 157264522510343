<script setup>
</script>

<template>
  <table class="table">
    <thead>
      <slot name="thead" />
    </thead>
    <tbody>
      <slot name="tbody" />
    </tbody>
  </table>
</template>

<style lang="scss" scoped>
.table {
  table-layout: auto;
  width: 100%;
  white-space: nowrap;
  tbody {
    tr {
      td {
        overflow: hidden;
      }
    }
  }
}
</style>
