<script setup></script>
<template>
  <fieldset>
    <legend class="text-start fs-5 border-bottom">
      <slot name="legend" />
    </legend>
    <div class="p-1">
      <slot />
    </div>
  </fieldset>
</template>
