<script setup>
import { useAttrs } from 'vue'
import BaseAlert from './BaseAlert.vue'
defineProps({
  isShow: {
    type: Boolean,
    default: false,
  }
})
const emit = defineEmits(['close'])
const attrs = useAttrs()
const handleClose = () => emit('close')
</script>

<template>
  <div
    v-if="isShow"
    class="">
    <BaseAlert
      v-bind="attrs"
      :is-closable="true"
      @close="handleClose">
      <slot />
    </BaseAlert>
  </div>
</template>