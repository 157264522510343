<script setup>
import Multiselect from '@vueform/multiselect'
import useModelWrapper from '@/composables/useModelWrapper'
const props = defineProps({
  label: {
    type: String,
    default: '',
  },
  modelValue: {
    type: Array,
    required: true
  },
  description: {
    type: String,
    default: '',
  },
  options: {
    type: [Array, Function],
    required: true
  },
  createTag: {
    type: Boolean,
    default: false
  },
  required: {
    type: Boolean,
    default: false
  }
})
const emit = defineEmits(['update:modelValue'])
const value = useModelWrapper(props, emit)
</script>

<template>
  <div class="row">
    <label :class="`d-block text-start`">
      {{ label }}
      <BaseSpacer size="sm" />
      <div class="row gx-0">
        <Multiselect
          v-model="value"
          mode="tags"
          :required="required"
          :options="options"
          :create-tag="createTag"
          :searchable="true"
          :min-chars="1" />
      </div>
    </label>
    <p
      v-if="description"
      class="col-md-12 text-start">
      <small class="fw-light">{{ description }}</small>
    </p>
  </div>
</template>

<style src="@vueform/multiselect/themes/default.css"></style>