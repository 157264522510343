<script setup>
import { useRouter } from 'vue-router'
import MonitorTable from '../components/MonitorTable.vue'

const router = useRouter()
</script>

<template>
  <main>
    <header class="header">
      <h4>通知先一覧</h4>
      <BaseSpacer />
      <BaseButton @click="router.push('/monitors/create')">
        新規登録
      </BaseButton>
    </header>
    <section
      class="mx-auto"
      style="max-width: 1000px;">
      <MonitorTable />
    </section>
  </main>
</template>
