<script setup>
import { saveWebsite } from '../api'
import { inject } from 'vue'
import { useRouter } from 'vue-router'
import { useSubmit } from '../composables/useSubmit'
import WebsiteForm from '@/components/WebsiteForm.vue'

const appAlert = inject('appAlert')
const router = useRouter()

const { submit, isSubmitting } = useSubmit(async (form) => {
  const result = await saveWebsite(form)
  appAlert(`正常に新規登録されました （ID: ${result.id}, 名前: ${result.name}）`)
  router.push({ name: 'websiteIndex' })
})
</script>

<template>
  <div>
    <h2 class="fs-4">
      サーバー監視作成
    </h2>
    <WebsiteForm
      :is-submitting="isSubmitting"
      @submit="submit">
      <template #submitText>
        新規作成
      </template>
    </WebsiteForm>
  </div>
</template>
