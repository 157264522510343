<script setup>
import { useAttrs } from 'vue'
const attrs = useAttrs()
</script>

<template>
  <div class="table-wrapper">
    <BaseTable v-bind="attrs">
      <template #thead>
        <slot name="thead" />
      </template>
      <template #tbody>
        <slot name="tbody" />
      </template>
    </BaseTable>
  </div>
</template>

<style lang="scss" scoped>
.table-wrapper {
    overflow: auto;
}
</style>