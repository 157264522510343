<script setup>
import { ref } from 'vue'
import MonitorFormDelete from './MonitorFormDelete.vue'
const props = defineProps({
  initialValue: {
    type: Object,
    default: () => ({
      id: null,
      name: '',
      addresses: [],
    }),
  },
  isSubmitting: {
    type: Boolean,
    default: false,
  },
})
const emit = defineEmits(['submit', 'delete'])
const form = ref(deepCopy(props.initialValue))

const handleSubmit = () => {
  // arrayのnullを削除する
  form.value.addresses = form.value.addresses.filter(v => v)

  emit('submit', form.value)
}
</script>

<template>
  <BaseContainerForm>
    <form
      class="row gy-4"
      @submit.prevent="handleSubmit">
      <BaseFieldset>
        <template #legend>
          <small>通知先情報</small>
        </template>
        <BaseInputGroup
          v-model="form.name"
          :max-size="8"
          label="名前"
          required />
      </BaseFieldset>
      <BaseFieldset>
        <template #legend>
          <small>通知先</small>
        </template>
        <BaseMultiInput
          v-model="form.addresses"
          :new-value="''"
          placeholder="メールアドレス または Slack Webhook URL"
          :required="true">
          <template #addBtnText>
            追加
          </template>
          <template #removeBtnText>
            削除
          </template>
        </BaseMultiInput>
      </BaseFieldset>
      <hr>
      <fieldset>
        <BaseSubmitButton :is-submitting="isSubmitting">
          <slot name="submitText">
            保存
          </slot>
        </BaseSubmitButton>
      </fieldset>
    </form>
    <fieldset>
      <hr>
      <MonitorFormDelete
        v-if="form.id"
        :id="form.id" />
    </fieldset>

    <BaseSpacer size="sm" />

    <p class="text-end">
      <router-link to="/monitors">
        一覧ページへ戻る <i class="bi bi-arrow-90deg-right" />
      </router-link>
    </p>
  </BaseContainerForm>
</template>
